import * as React from "react";

function Ozon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={109} height={25} fill="#005BFF" {...props} viewBox="0 0 109 25">
      <path
        d="M13.157.814C6.613.117.774 4.797.07 11.27c-.705 6.473 4.027 12.249 10.57 12.946 6.544.697 12.383-3.984 13.088-10.457.1-.796.1-1.593 0-2.39-.604-5.577-5.034-9.959-10.57-10.556zm-.402 17.926c-3.524.498-6.645-1.992-7.148-5.378-.504-3.486 2.013-6.573 5.436-7.07 3.524-.499 6.645 1.99 7.148 5.377.503 3.386-2.013 6.672-5.436 7.07zM30.07 1.312c-1.51 0-2.82 1.195-2.82 2.788 0 .2 0 .399.101.598.302 1.294 1.51 2.19 2.82 2.19h7.751l-12.08 15.736c-.303.298-.202.796.2 1.095.101.1.303.2.504.2h18.927c1.309 0 2.517-.897 2.819-2.192.302-1.494-.604-2.987-2.114-3.286-.202 0-.403-.1-.604-.1H36.11l11.98-15.735c.303-.398.202-.796-.2-1.095-.102-.1-.303-.2-.504-.2H30.07zm75.506 0c-1.309.299-2.215 1.494-2.215 2.788v9.162l-15-11.85c-.302-.3-.806-.2-1.108.099-.1.1-.201.299-.201.498V20.93c0 1.294.906 2.49 2.215 2.788 1.51.299 3.02-.598 3.322-2.091 0-.2.1-.399.1-.598v-9.262l15.001 11.851c.302.3.806.2 1.108-.1a.76.76 0 00.201-.497V4a2.799 2.799 0 00-2.819-2.789c-.201.1-.403.1-.604.1zm-39.062 4.98c6.343 0 11.074 3.286 11.074 6.273 0 2.988-4.731 6.274-11.074 6.274-6.342 0-11.074-3.286-11.074-6.274 0-2.987 4.732-6.274 11.074-6.274zm0-5.578c-9.161 0-16.611 5.278-16.611 11.752 0 6.473 7.45 11.75 16.611 11.75 9.162 0 16.611-5.277 16.611-11.75 0-6.474-7.45-11.752-16.61-11.752z"
        fill="inherit"
      />
    </svg>
  );
}

const MemoOzon = React.memo(Ozon);
export default MemoOzon;
